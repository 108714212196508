<template>
  <ValidationObserver @submit="newTeacher">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :disable-position-center="true"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit">
          <span v-i18n="dashboard">Edit Teacher</span>
        </span>
        <span v-else v-i18n="dashboard">Add Teacher</span>
      </template>
      <div>
        <div class="checkInModal-body">
          <UIStepper :steps="steps" :edit="edit" />

          <div v-if="currentStat === 1">
            <InputFieldWrapper>
              <UiInput
                v-model="teacher.teacher_info.first_name"
                type="text"
                name="First Name"
                title="First Name"
                label="First Name"
                placeholder="First Name"
                class="flex-1"
                rules="required|alpha_spaces"
              />
              <UiInput
                v-model="teacher.teacher_info.last_name"
                type="text"
                name="Last Name"
                title="Last Name"
                label="Last Name"
                placeholder="Last Name"
                class="flex-1"
                rules="required|alpha_spaces"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiSingleSelect
                v-model="teacher.teacher_info.gender"
                title="Gender"
                :options="genders"
                class="flex-1"
              />
              <UiInput
                id="img-btn"
                ref="imgUpload"
                v-model="teacherImage"
                title="Photo"
                placeholder="Choose a file"
                type="file"
                class="flex-1"
                :preview-image="previewImage"
                :rules="imageRules"
                @preview="preview"
                @clearValue="clearValue"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiInput
                v-model="teacher.teacher_info.email"
                type="email"
                name="Email"
                title="Email"
                label="Email"
                placeholder="Email"
                class="flex-1"
                :rules="isTeacherActivated && !teacherNumber && !edit ? 'email|required' : 'email'"
              />
              <PhoneNumber
                v-model="teacherNumber"
                title="Phone Number"
                :rules="
                  isTeacherActivated && !teacher.teacher_info.email && !edit
                    ? 'required|validNumber'
                    : 'validNumber'
                "
                class="flex-1"
                :disabled="!!reset"
                @update="updateTeacherNumber"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiDatePicker
                v-model="teacher.profile_info.date_of_birth"
                class="flex-1"
                title="DOB"
              />
              <UiDatePicker
                v-model="teacher.professional_info.join_date"
                title="Join Date"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="teacher.profile_info.blood_group"
                title="Blood Group"
                :options="['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']"
                class="flex-1"
              />
              <UiInput
                v-model="teacher.teacher_info.address"
                type="text"
                name="Address"
                title="Address"
                label="Address"
                placeholder="Address"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper class="flex-col-reverse">
              <UiSingleSelect
                v-model="teacher.identity_info.type"
                title="Identity Type"
                :options="identityType"
                label="title"
                reduce="value"
                class="flex-1"
                @change="resetData"
              />
              <UiInput
                v-model.trim="teacher.profile_info.religion"
                type="text"
                name="Religion"
                title="Religion"
                label="Religion"
                placeholder="Religion"
                class="flex-1"
                rules="alpha_spaces"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <div
                v-if="teacher.identity_info.type"
                class="flex sm:flex-row flex-col sm:gap-6 w-full"
              >
                <UiInput
                  v-model="teacher.identity_info.value"
                  type="text"
                  name="Identity Number"
                  title="Identity Number"
                  label="Identity Number"
                  placeholder="Enter Identity No"
                  class="flex-1"
                  rules="required:|numeric"
                />
                <UiInput
                  v-model="teacher.identity_info.country"
                  type="text"
                  name="Country"
                  title="Country"
                  label="Country"
                  placeholder="Enter Country"
                  class="flex-1"
                  rules="required:|alpha"
                />
              </div>
            </InputFieldWrapper>
            <InputFieldWrapper class="justify-end">
              <ToggleButton
                v-if="!edit"
                v-model="isTeacherActivated"
                heading="Enable Login"
                :is-status-hide="true"
                class="w-full sm:w-[48%]"
              />
            </InputFieldWrapper>
          </div>
          <div v-if="currentStat === 2 && teacher.acl_info">
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="teacher.acl_info.campus"
                title="CAMPUS"
                :options="campuses"
                label="title"
                class="flex-1"
                :select-object-without-reduce-key="true"
                :search-through-api="true"
                rules="required"
                :disabled="!!currentCampusScope"
                @search="getCampus"
                @change="getClasses()"
                @deSelect="getClasses()"
              />
              <UiSingleSelect
                v-model="teacher.acl_info.class"
                title="CLASS"
                :options="usersClassList"
                label="title"
                :disabled="!!currentClassScope || !teacher.acl_info.campus?.id"
                class="flex-1"
                :select-object-without-reduce-key="true"
                :search-through-api="true"
                @change="setSection(teacher.acl_info.class.id)"
                @deSelect="setSection(teacher.acl_info.class.id)"
                @search="getClass"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="teacher.acl_info.section"
                title="SECTION"
                placeholder="Select Teacher Section"
                :options="usersSectionList"
                label="title"
                class="flex-1"
                :select-object-without-reduce-key="true"
                :rules="sectionRequiredWhenClassScope"
                :disabled="currentSectionScope || !teacher.acl_info.class?.id"
                :search-through-api="true"
                @change="getSubjects()"
                @search="getSection"
              />
              <UiSingleSelect
                v-model="teacher.acl_info.subject_id"
                title="Teacher Subject"
                placeholder="Teacher Subject"
                :options="subjects"
                reduce="id"
                :search-through-api="true"
                label="title"
                class="flex-1"
                :disabled="!teacher.acl_info.section?.id"
                @search="getSubjects"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div v-if="currentStat > 1" class="flex flex-1">
          <UIButton button="primary-previous" :icon="true" @click="prevStep">Previous</UIButton>
        </div>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <template v-if="isLoading">
              <Loader></Loader>
            </template>
            <template v-else>
              <span>{{ primaryButtonText }}</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* Components */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import { mapActions, mapState } from 'vuex'
import { transformTeacherData } from '@src/utils/settings/tenant-user.util.js'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { addItemInArray } from '@utils/generalUtil'
import UIButton from '@src/components/UiElements/UIButton.vue'
import TabMixins from '@src/mixins/components/tab-mixin.js'
import UIStepper from '@src/components/UiElements/UIStepper.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    UiInput,
    UiDatePicker,
    UiSingleSelect,
    Loader,
    PhoneNumber,
    ToggleButton,
    UIButton,
    UIStepper,
    UIButton,
    InputFieldWrapper,
  },
  mixins: [TabMixins],
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
    reset: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  data() {
    return {
      setFormData: '',
      teacherNumber: '',
      options: ['Select'],
      // progress steper start
      previewImage: null,
      teacherImage: null,
      identityType: [
        { title: this.$t(`dashboard.TM_P2_DD2_O1_L1`), value: 'cnic' },
        { title: this.$t(`dashboard.TM_P2_DD2_O2_L2`), value: 'driving_licence' },
        { title: this.$t(`dashboard.TM_P2_DD2_O3_L3`), value: 'passport' },
      ],
      isTeacherActivated: true,
      genders: [this.$t(`dashboard.male`), this.$t(`dashboard.female`), this.$t(`dashboard.other`)],
      dashboard: 'dashboard',
      subjects: [],
      steps: [
        { stat: 1, info: 'Personal Info', active: true, markStatus: false },
        { stat: 2, info: 'Enrollment Info', active: false, markStatus: false },
      ],
      // Progress steper end
      teacher: {
        teacher_info: {
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          phone: '',
          gender: '',
          user_type: 'teacher',
          login_activated_at: '',
          address: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          image: '',
        },
        professional_info: {
          qualification: '',
          join_date: '',
          designation: '',
        },
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
        salary_info: {
          cycle: '',
          allowance: '',
          amount: '',
          other_perks: '',
        },
        acl_info: {
          campus: null,
          section: null,
          class: null,
          subject_id: '',
        },
      },
    }
  },
  computed: {
    ...mapState('layout', [
      'campuses',
      'isLoading',
      'currentCampusScope',
      'currentClassScope',
      'currentSectionScope',
    ]),
    ...mapState({ usersClassList: (state) => state.classes.usersClassList }),
    ...mapState({ usersSectionList: (state) => state.section.usersSectionList }),
    ...mapState({ campuses: (state) => state.layout.campuses }),
    ...mapState('instituteCount', ['instituteCount']),
    sectionRequiredWhenClassScope() {
      return this.currentClassScope || this.teacher.acl_info.class?.id ? 'required' : ''
    },
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },
    primaryButtonText() {
      return (!this.edit && this.currentStat < 2) || (this.edit && this.currentStat < 1)
        ? 'Next'
        : this.edit
        ? 'Update'
        : 'Save'
    },
  },
  watch: {
    'teacher.acl_info.campus': {
      handler() {
        /* LOGIC FOR INSTITUTE */
        this.teacher.acl_info.class = null
        this.teacher.acl_info.section = null
        this.teacher.acl_info.subject_id = ''
        this.subjects = []
      },
    },

    'teacher.acl_info.class': {
      handler() {
        /* LOGIC FOR INSTITUTE */

        this.teacher.acl_info.section = null
        this.teacher.acl_info.subject_id = ''
        this.subjects = []
      },
    },
    'teacher.acl_info.section': {
      handler() {
        /* LOGIC FOR INSTITUTE */

        this.teacher.acl_info.subject_id = ''
        this.subjects = []
      },
    },
  },
  created() {
    if (this.edit) {
      this.setTeaherEditDate()
      this.steps.length = 1
      this.teacherNumber = this.edit?.phone || ''
    }
    this.instituteScopeHandler()
    this.setData()
  },
  methods: {
    currentUTCDateTimeWithZone,
    resetData() {
      this.teacher.identity_info.value = ''
      this.teacher.identity_info.country = ''
    },
    setData() {
      let currentCampus = this.teacher?.acl_info?.campus
      let currentClass = this.teacher?.acl_info?.class
      let currentSection = this.teacher?.acl_info?.section
      if (currentCampus?.id) {
        this.$store.commit('layout/GET_ALL_CAMPUS', addItemInArray(currentCampus, this.campuses))
      }
      if (currentClass?.id) {
        this.$store.commit(
          'classes/SET_USERS_CLASS_LIST',
          addItemInArray(currentClass, this.usersClassList),
        )
      }
      if (currentSection?.id) {
        this.$store.commit(
          'section/SET_USER_SECTIONS_LIST',
          addItemInArray(currentSection, this.usersSectionList),
        )
      }
    },
    instituteScopeHandler() {
      if (this.currentCampusScope?.id && this.teacher.acl_info) {
        this.teacher.acl_info.campus = this.currentCampusScope
        this.getClasses()
      }
    },
    getClass(query = '') {
      this.getClasses(this.teacher.acl_info.campus?.id, query)
    },
    getClasses(id, query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'campus_id',
            this.teacher.acl_info?.campus?.id || this.currentCampusScope?.id,
          ),
        },
      }
      this.getUserClasses(payload).then(() => {
        if (this.currentClassScope?.id && this.teacher.acl_info) {
          this.teacher.acl_info.class = this.currentClassScope
          this.setSection(this.teacher.acl_info.class?.id)
        }
      })
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    getSection(query = '') {
      this.setSection(this.teacher.acl_info.class?.id, query)
    },
    setSection(id, query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'class_id', id || this.currentClassScope?.id),
        },
      }
      this.filterUsersSectionsList(payload).then(() => {
        if (this.currentSectionScope?.id && this.teacher.acl_info) {
          this.teacher.acl_info.section = this.currentSectionScope
          this.getSubjects()
        }
      })
    },
    getSubjects(query = '') {
      let filtersPayload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'section_id',
            this.teacher.acl_info?.section?.id || '',
          ),
          ...buildWhereQuery(
            FILTER_KEYS.EQUAL,
            'class_id',
            this.teacher?.acl_info?.class?.id || this.currentClassScope?.id,
          ),
        },
      }
      this.classSubjects(filtersPayload).then((result) => {
        this.subjects = result.data.records
      })
    },
    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.teacherImage = image
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.$store.commit('tab/SET_CURRENT_STAT', (this.currentStat = 1))
          this.previewImage = null
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'confirm':
          break
      }
    },
    newTeacher() {
      try {
        if (this.currentStat === 2 || (this.currentStat === 1 && this.edit)) {
          if (this.edit) this.editExistingTeacher()
          else this.addNewTeacher()
        } else this.nextStep()
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
        this.$store.commit(
          'toast/NEW',
          { type: 'error', message: this.$t(`toast.Invalid Data`) },
          { root: true },
        )
      }
    },
    /* UPDATE TEACHER NUMBER */
    updateTeacherNumber(payload) {
      this.teacher.teacher_info.phone = payload.formattedNumber
    },

    editExistingTeacher() {
      this.teacher.teacher_info.login_activated_at = this.isTeacherActivated
        ? this.currentUTCDateTimeWithZone()
        : ''

      const form = new FormData()
      if (this.teacherImage) form.append('profile_image', this.teacherImage)
      form.append('first_name', this.teacher.teacher_info.first_name)
      form.append('last_name', this.teacher.teacher_info.last_name)
      form.append('gender', this.teacher.teacher_info.gender)
      form.append('phone', this.teacher.teacher_info.phone)
      form.append('email', this.teacher.teacher_info.email)
      form.append('user_type', this.teacher.teacher_info.user_type)
      form.append('address', this.teacher.teacher_info.address)
      form.append('login_activated_at', this.teacher.teacher_info.login_activated_at)
      form.append('blood_group', this.teacher.profile_info.blood_group)
      form.append('date_of_birth', this.teacher.profile_info.date_of_birth)
      form.append('join_date', this.teacher.professional_info.join_date)
      form.append('religion', this.teacher.profile_info.religion)
      form.append('type', this.teacher.identity_info.type)
      form.append('value', this.teacher.identity_info.value)
      form.append('country', this.teacher.identity_info.country)

      const data = {
        teacher: form,
        id: this.edit?.id,
      }
      this.editTeacher(data)
        .then((res) => {
          let teacherMode = !!localStorage.getItem('currentActiveTeacher')
          if (teacherMode) localStorage.setItem('currentActiveTeacher', JSON.stringify(res.data))

          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.previewImage = null
        })
    },

    async addNewTeacher() {
      this.$store.commit('layout/IS_LOADING', true)

      if (this.isTeacherActivated)
        this.teacher.teacher_info.login_activated_at = this.currentUTCDateTimeWithZone()

      const data = new FormData()
      if (this.teacherImage) {
        data.append('profile_image', this.teacherImage)
      }
      data.append('first_name', this.teacher.teacher_info.first_name)
      data.append('last_name', this.teacher.teacher_info.last_name)
      data.append('gender', this.teacher.teacher_info.gender)
      data.append('phone', this.teacher.teacher_info.phone)
      data.append('email', this.teacher.teacher_info.email)
      data.append('user_type', this.teacher.teacher_info.user_type)
      data.append('address', this.teacher.teacher_info.address)
      data.append('login_activated_at', this.teacher.teacher_info.login_activated_at)
      data.append('blood_group', this.teacher.profile_info.blood_group)
      data.append('date_of_birth', this.teacher.profile_info.date_of_birth)
      data.append('join_date', this.teacher.professional_info.join_date)
      data.append('religion', this.teacher.profile_info.religion)
      data.append('type', this.teacher.identity_info.type)
      data.append('value', this.teacher.identity_info.value)
      data.append('country', this.teacher.identity_info.country)

      if (!this.currentCampusScope) data.append('campus_id', this.teacher.acl_info.campus?.id || '')
      if (this.teacher.acl_info.class?.id)
        data.append('class_id', this.teacher.acl_info.class?.id || '')
      if (!this.currentSectionScope)
        data.append('section_id', this.teacher.acl_info.section?.id || '')
      data.append('subject_id', this.teacher.acl_info.subject_id)

      await this.addTeacher(data).then(() => {
        this.$store.commit('layout/IS_LOADING', false)
        this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'teachers_count')
        this.$store.commit('tab/SET_CURRENT_STAT', (this.currentStat -= 1))
        this.getInstituteCount()
      })
      this.$emit('toggle', true)
    },

    setTeaherEditDate() {
      this.teacher = transformTeacherData(this.edit)
      this.previewImage = {}
      this.previewImage.src = this.edit?.image || (this.previewImage = null)
      this.isTeacherActivated = !!this.edit.login_activated_at
    },

    ...mapActions('teacher', ['addTeacher', 'editTeacher']),
    ...mapActions('section', ['filterUsersSectionsList']),
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('layout', ['getCampusList']),
    ...mapActions('classes', ['getUserClasses']),
    ...mapActions('instituteCount', ['getInstituteCount']),
  },
}
</script>
