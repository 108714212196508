<template>
  <ValidationObserver @submit="addStaff">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :disable-position-center="true"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit">Edit Staff</span>
        <span v-else v-i18n="dashboard">Add Staff</span>
      </template>
      <template v-slot>
        <div class="checkInModal-body">
          <div v-if="currentStat === 1">
            <InputFieldWrapper>
              <UiInput
                v-model="staffData.staff_info.first_name"
                type="text"
                name="First Name"
                title="First Name"
                label="First Name"
                placeholder="Enter First Name"
                rules="required|alpha_spaces"
                class="flex-1"
              />
              <UiInput
                v-model="staffData.staff_info.last_name"
                type="text"
                name="Last Name"
                title="Last Name"
                label="Last Name"
                placeholder="Enter Last Name"
                rules="required|alpha_spaces"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="staffData.staff_info.gender"
                title="Gender"
                :options="genders"
              />
              <UiInput
                id="img-btn"
                ref="imgUpload"
                v-model="staffImage"
                title="Photo"
                placeholder="Photo Upload"
                :preview-image="previewImage"
                type="file"
                :rules="imageRules"
                @preview="preview"
                @clearValue="clearValue"
              />

              <!-- alpha_spaces -->
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiInput
                v-model="staffData.staff_info.email"
                type="email"
                name="Email"
                title="Email"
                label="Email"
                :rules="isEmailRequired ? 'required|email' : 'email'"
                placeholder="Email"
                class="flex-1"
              />
              <PhoneNumber
                v-model="staffNumber"
                title="Phone Number"
                :rules="isPhoneNumberRequired ? 'required|validNumber' : 'validNumber'"
                class="flex-1"
                @update="updateStaffNumber"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiDatePicker
                v-model="staffData.profile_info.date_of_birth"
                title="DOB"
                :disable-after="true"
                class="flex-1"
              />

              <UiDatePicker
                v-model="staffData.professional_info.join_date"
                title="Join Date"
                class="flex-1"
              />
            </InputFieldWrapper>

            <InputFieldWrapper>
              <UiSingleSelect
                v-model="staffData.profile_info.blood_group"
                title="Blood Group"
                :options="bloodGroup"
              />

              <UiInput
                v-model="staffData.profile_info.address"
                type="text"
                name="Address"
                title="Address"
                label="Address"
                placeholder="Address"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiInput
                v-model="staffData.profile_info.religion"
                type="text"
                name="Religion"
                title="Religion"
                label="Religion"
                placeholder="Relegion"
                rules="alpha_spaces"
                class="flex-1"
              />
              <UiSingleSelect
                v-model="staffData.staff_info.major_role"
                :options="roles"
                class="flex-1"
                title="Major Responsibility"
                placeholder="Select"
                @change="resetOtherRole"
              />
            </InputFieldWrapper>
            <InputFieldWrapper class="flex-col-reverse">
              <UiSingleSelect
                v-model="staffData.identity_info.type"
                title="Identity Type"
                :options="identityType"
                label="title"
                reduce="value"
                class="flex-1"
                @change="setIdentityInfo()"
              />
              <template v-if="staffData.staff_info.major_role === 'other'">
                <UiInput
                  v-model="otherRole"
                  type="text"
                  name="Major Role"
                  title="Enter Major Role"
                  label="Major Role"
                  placeholder="Enter Major Role"
                  class="flex-1"
                  rules="required"
                />
              </template>
              <UiSingleSelect
                v-if="!edit && !currentCampusScope"
                v-model="staffData.staff_info.campus_id"
                title="CAMPUSES"
                :search-through-api="true"
                label="title"
                :options="campuses"
                class="flex-1"
                reduce="id"
                rules="required"
                @search="getCampus"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <div
                v-if="staffData.identity_info.type"
                class="flex sm:flex-row flex-col sm:gap-6 w-full"
              >
                <UiInput
                  v-model="staffData.identity_info.value"
                  type="text"
                  name="Identity Number"
                  title="Identity Number"
                  label="Identity Number"
                  placeholder="Enter Identity No"
                  rules="required|numeric"
                  class="flex-1"
                />
                <UiInput
                  v-model="staffData.identity_info.country"
                  type="text"
                  name="Country"
                  title="Country"
                  label="Country"
                  placeholder="Enter Country"
                  rules="required|alpha"
                  class="flex-1"
                />
              </div>
            </InputFieldWrapper>

            <InputFieldWrapper class="justify-end">
              <ToggleButton
                v-if="!edit"
                v-model="isStaffActivated"
                class="w-full sm:w-[48%]"
                heading="Enable Login"
                :is-status-hide="true"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </template>
      <template v-if="currentStat < 5" v-slot:footer>
        <div v-if="currentStat > 1" class="flex flex-1">
          <UIButton button="primary-previous" :icon="true" @click="prevStep">Previous</UIButton>
        </div>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ title }}
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* COMPONENTS */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import { transformStaffData } from '@src/utils/settings/tenant-user.util.js'
import Loader from '@components/BaseComponent/Loader.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
import { Form as ValidationObserver } from 'vee-validate'
/* vuex */
import { mapActions, mapState } from 'vuex'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    UiInput,
    UiDatePicker,
    UiSingleSelect,
    ValidationObserver,
    Loader,
    PhoneNumber,
    ToggleButton,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      previewImage: null,
      staffImage: null,
      staffNumber: '',
      currentStat: 1,
      otherRole: '',
      dashboard: 'dashboard',
      modalStatus: false,
      genders: [this.$t(`dashboard.male`), this.$t(`dashboard.female`), this.$t(`dashboard.OTHER`)],
      bloodGroup: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      identityType: [
        { title: this.$t(`dashboard.TM_P2_DD2_O1_L1`), value: 'cnic' },
        { title: this.$t(`dashboard.TM_P2_DD2_O2_L2`), value: 'driving_licence' },
        { title: this.$t(`dashboard.TM_P2_DD2_O3_L3`), value: 'passport' },
      ],
      roles: [
        this.$t(`dashboard.RESPONSIBILITY1`),
        this.$t(`dashboard.RESPONSIBILITY2`),
        this.$t(`dashboard.RESPONSIBILITY3`),
        this.$t(`dashboard.RESPONSIBILITY4`),
        this.$t(`dashboard.RESPONSIBILITY5`),
        this.$t(`dashboard.OTHER`),
      ],
      salaryCycle: [
        this.$t(`dashboard.TM_P4_DD1`),
        this.$t(`dashboard.TM_P4_DD2`),
        this.$t(`dashboard.TM_P4_DD3`),
        this.$t(`dashboard.TM_P4_DD4`),
        this.$t(`dashboard.TM_P4_DD5`),
      ],
      isStaffActivated: true,
      staffData: {
        staff_info: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          gender: '',
          campus_id: '',
          user_type: 'staff',
          major_role: '',
          login_activated_at: '',
        },
        profile_info: {
          address: '',
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          passport: '',
          cnic: '',
          driving_lisence: '',
          image: '',
        },
        professional_info: {
          qualification: '',
          join_date: '',
          designation: '',
          salary: '',
          department_id: '',
        },
        identity_info: {
          type: '',
          value: '',
          country: '',
        },
        salary_info: {
          cycle: '',
          allowance: '',
          amount: '',
          other_perks: '',
        },
        acl_info: {},
      },
    }
  },

  computed: {
    ...mapState('layout', ['campuses', 'isLoading', 'currentCampusScope']),
    ...mapState('instituteCount', ['instituteCount']),
    title() {
      if (this.currentStat === 1 && this.edit) {
        return 'Update'
      } else if (this.currentStat === 1) {
        return 'Save'
      } else {
        return 'Next'
      }
    },
    isEmailRequired() {
      return this.isStaffActivated && !this.staffNumber
    },
    isPhoneNumberRequired() {
      return this.isStaffActivated && !this.staffData.staff_info.email
    },
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },
  },
  created() {
    if (this.edit) {
      this.setStaffData()
    }
  },
  methods: {
    currentUTCDateTimeWithZone,
    setIdentityInfo() {
      this.staffData.identity_info.value = ''
      this.staffData.identity_info.country = ''
    },
    resetOtherRole() {
      this.otherRole = ''
    },
    nextStep() {
      if (this.currentStat < this.steps.length) {
        for (let i = 0; i <= this.currentStat; i++) {
          this.steps[i].active = true
          if (this.steps[i].active === true) {
            if (i !== 0) {
              this.steps[i - 1].markStatus = true
            }
          }
        }
      }
      if (this.currentStat <= 5) {
        this.currentStat += 1
      }
    },
    prevStep() {
      if (this.currentStat > 1) {
        this.currentStat -= 1
      }
      if (this.currentStat > 0) {
        for (let i = 0; i <= this.currentStat; i++) {
          this.steps[i].active = true
        }
        this.steps[this.currentStat].active = false
        this.steps[this.currentStat].markStatus = false
        this.steps[this.currentStat - 1].markStatus = false
      }
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'checkInModalOpen':
          this.$emit('toggle')
          break
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'confirm':
          break
      }
    },

    updateStaffNumber(payload) {
      this.staffData.staff_info.phone = payload.formattedNumber
    },
    /**
     * Set staff data to staff object when we edit staff member
     */

    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.staffImage = image
    },

    setStaffData() {
      this.staffData = transformStaffData(this.edit)
      if (!this.roles.includes(this.staffData?.staff_info?.major_role)) {
        this.otherRole = this.staffData?.staff_info?.major_role
        this.staffData.staff_info.major_role = 'other'
      }
      this.previewImage = {}
      this.previewImage.src = this.edit?.image || (this.previewImage = null)
      this.staffNumber = this.edit?.phone
      this.isStaffActivated = !!this.edit?.login_activated_at
    },
    /**
     * Set role when major role is other than admin and teacher
     */

    addStaff() {
      if (this.currentStat === 1) this.addEditStaffMember()
      else this.nextStep()
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },

    /**
     * Add new staff or edit existing staff member
     * Show toast when successfulyy added or updated
     */
    addEditStaffMember() {
      this.$store.commit('layout/IS_LOADING', true)
      if (this.edit) this.editExistingStaffStaff()
      else this.addNewStaff()
    },

    async addNewStaff() {
      this.$store.commit('layout/IS_LOADING', true)
      if (this.isStaffActivated)
        this.staffData.staff_info.login_activated_at = this.currentUTCDateTimeWithZone()

      const data = new FormData()
      if (this.staffImage) {
        data.append('profile_image', this.staffImage)
      }
      if (this.staffData.staff_info.major_role === 'other') {
        data.append('major_role', this.otherRole)
      } else {
        data.append('major_role', this.staffData.staff_info.major_role)
      }
      data.append('first_name', this.staffData.staff_info.first_name)
      data.append('last_name', this.staffData.staff_info.last_name)
      data.append('gender', this.staffData.staff_info.gender)
      data.append('campus_id', this.staffData.staff_info.campus_id)
      data.append('phone', this.staffData.staff_info.phone)
      data.append('email', this.staffData.staff_info.email)
      data.append('user_type', this.staffData.staff_info.user_type)
      data.append('address', this.staffData.profile_info.address)
      data.append('login_activated_at', this.staffData.staff_info.login_activated_at)
      data.append('blood_group', this.staffData.profile_info.blood_group)
      data.append('date_of_birth', this.staffData.profile_info.date_of_birth)
      data.append('join_date', this.staffData.professional_info.join_date)
      data.append('religion', this.staffData.profile_info.religion)
      data.append('type', this.staffData.identity_info.type)
      data.append('value', this.staffData.identity_info.value)
      data.append('country', this.staffData.identity_info.country)
      await this.createStaff(data).then(() => {
        this.$store.commit('layout/IS_LOADING', false)
        this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'staff_count')
        this.getInstituteCount()
      })
      this.$store.commit('layout/IS_LOADING', false)

      this.$emit('toggle', true)
    },
    async editExistingStaffStaff() {
      this.staffData.staff_info.login_activated_at = this.isStaffActivated
        ? this.currentUTCDateTimeWithZone()
        : ''
      const form = new FormData()
      if (this.staffImage) form.append('profile_image', this.staffImage)
      if (this.staffData.staff_info.major_role === 'other') {
        form.append('major_role', this.otherRole)
      } else {
        form.append('major_role', this.staffData.staff_info.major_role)
      }
      form.append('first_name', this.staffData.staff_info.first_name)
      form.append('last_name', this.staffData.staff_info.last_name)
      form.append('gender', this.staffData.staff_info.gender)
      form.append('phone', this.staffData.staff_info.phone)
      form.append('email', this.staffData.staff_info.email)
      form.append('user_type', this.staffData.staff_info.user_type)
      form.append('address', this.staffData.profile_info.address)
      form.append('login_activated_at', this.staffData.staff_info.login_activated_at)
      form.append('blood_group', this.staffData.profile_info.blood_group)
      form.append('date_of_birth', this.staffData.profile_info.date_of_birth)
      form.append('join_date', this.staffData.professional_info.join_date)
      form.append('religion', this.staffData.profile_info.religion)
      form.append('type', this.staffData.identity_info.type)
      form.append('value', this.staffData.identity_info.value)
      form.append('country', this.staffData.identity_info.country)

      let data = {
        staff: form,
        id: this.edit?.id,
      }
      await this.updateStaff(data)
      this.$store.commit('layout/IS_LOADING', false)
      this.$emit('toggle', true)
    },
    ...mapActions('layout', ['getCampusList']),
    ...mapActions('instituteCount', ['getInstituteCount']),
    ...mapActions('staff', ['createStaff', 'updateStaff']),
  },
}
</script>
