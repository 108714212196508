<template>
  <ValidationObserver @submit="addAndUpdateSubjecthandler">
    <UiModalContainer
      :modal-show="modal"
      :modal-width="50"
      footer-classes="flex justify-end gap-3"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ modalHeading }}
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <UiInput
              v-model="subjectInfo.title"
              type="text"
              name="Subject Title"
              title="Subject Title"
              label="Subject Title"
              placeholder="Subject Title"
              class="flex-1"
              rules="required"
            />
            <UiInput
              v-model="subjectInfo.course_code"
              type="text"
              name="Concise Name"
              title="Concise Name"
              label="Concise Name"
              placeholder="Concise Name"
              class="flex-1"
              rules="required"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiSingleSelect
              v-model="subjectInfo.class_id"
              title="Class"
              :options="classesList"
              label="title"
              :disabled="!!activeClass"
              reduce="id"
              rules="required"
              class="flex-1"
            />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary">
          <span v-if="isLoading">
            <Loader />
          </span>
          <span v-else>{{ saveBtnTitle }}</span>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions, mapState } from 'vuex'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiModalContainer,
    UiSingleSelect,
    ValidationObserver,
    UIButton,
    Loader,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      options: ['Select'],
      dashboard: 'dashboard',
      enrolledTeacher: [],
      isActive: false,
      isOpen: false,
      colorOptions: [],
      subjectInfo: {
        title: '',
        course_code: '',
        class_id: '',
      },
    }
  },
  computed: {
    headingTitle() {
      return this.edit ? `Edit ${this.edit.title}` : 'Add Subject'
    },
    ...mapState({
      classesList: (state) => state.classes.classesList,
      isLoading: (state) => state.layout.isLoading,
      activeClass: (state) => state.layout.currentClassScope?.id,
    }),
    ...mapState('layout', ['campuses', 'currentCampusScope', 'currentSectionScope']),
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    modalHeading() {
      return this.edit ? `Edit ${this.edit.title}` : 'Add Subject'
    },
  },
  /**
   * Created Hook
   * @description Gets the classes list and setting colors to different elements
   */
  created() {
    this.getClassesList()

    if (this.activeClass) {
      this.subjectInfo = {
        class_id: this.activeClass,
      }
    }
    if (this.edit)
      this.subjectInfo = {
        title: this.edit.title,
        course_code: this.edit.course_code,
        class_id: this.edit.class.id,
      }
  },
  methods: {
    /**
     * Toggle dropdown
     * @description Toggling dropdown
     */
    toggleDropdown() {
      this.isActive = !this.isActive
      this.toggleIsOpen()
    },
    /**
     * toggle IsOpen
     * @param {Boolean | *} value
     * @returns {void}
     * @description Toggling the value of IsOpen
     */
    toggleIsOpen(value) {
      this.isOpen = typeof value === 'boolean' ? value : !this.isOpen
    },
    /**
     * Close dropdown
     * @description Closing the drop down menus
     */
    closeDropdown() {
      if (this.isActive) {
        this.isActive = false
        this.toggleIsOpen()
      }
    },
    /**
     * Handle Click
     * @param {String} eventName
     * @returns {void}
     * @description Function Description :
     * - Handling click on the basis of parameter
     * - Emiting toggle to parent component
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.$emit('toggle')
          break
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'confirm':
          break
      }
    },
    /**
     * addAndUpdateSubjecthandler
     * @param {Boolean} invalid
     * @returns {void}
     * @description Function Description :
     * - Checking form
     * - Emiting toggle to parent component
     */
    addAndUpdateSubjecthandler() {
      const data = {
        subject: this.subjectInfo,
      }
      if (this.edit) data.id = this.edit.id
      this.$store.commit('layout/IS_LOADING', true)
      this.newSubject(data).then(() => {
        this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'subjects_count')
        this.$emit('toggle', true)
      })
      this.$store.commit('layout/IS_LOADING', false)
    },
    ...mapActions('classes', ['getClassesList']),
    ...mapActions('subjects', ['newSubject']),
  },
}
</script>
