<template>
  <ValidationObserver @submit="addAndEditSectionHandler">
    <UiModalContainer
      :modal-show="modal"
      :modal-width="50"
      footer-classes="flex justify-end gap-3"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit" v-i18n="dashboard">Edit Section</span>
        <span v-else v-i18n="dashboard">Add Section</span>
      </template>
      <template v-slot>
        <InputFieldWrapper class="sm:flex-row sm:gap-3 flex-col flex">
          <UiInput
            v-model="classData.className"
            type="text"
            title="Section Name"
            placeholder="Section Name"
            class="flex-1"
            rules="required"
          />
          <UiInput
            v-model="classData.classDescription"
            type="text"
            name="Section Description"
            title="Section Description"
            label="Section Description"
            placeholder="Section Description"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="classData.selectedClass"
            title="CLASS"
            label="title"
            :options="classesList"
            :disabled="!!currentClassScope"
            reduce="id"
            rules="required"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              {{ primaryButtonText }}
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions } from 'vuex'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiModalContainer,
    UiSingleSelect,
    ValidationObserver,
    UIButton,
    Loader,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      options: ['Select'],
      dashboard: 'dashboard',
      isActive: false,
      isOpen: false,
      classData: {
        className: '',
        classDescription: '',
        startTime: '',
        endTime: '',
        selectedClass: '',
      },
    }
  },
  computed: {
    primaryButtonText() {
      return this.edit ? 'Update' : 'Save'
    },
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
      campusId: (state) => state.layout.currentCampusScope?.id || undefined,
      classesList: (state) => state.classes.classesList,
      instituteCount: (state) => state.instituteCount.instituteCount,
      currentClassScope: (state) => state.layout.currentClassScope,
    }),
  },
  /**
   * Created Hook
   * @description Description :
   * - getting the list of classes
   * - setting the class data
   */
  created() {
    this.getClassesList()
    if (this.edit) this.setFomData()
    if (this.currentClassScope) this.classData.selectedClass = this.currentClassScope.id
  },
  methods: {
    ...mapActions('classes', ['getClassesList']),
    ...mapActions('section', ['getClassSections', 'addNewSection']),
    /**
     * toggle Dropdown
     * @description toggling dropdown
     */
    toggleDropdown() {
      this.isActive = !this.isActive
      this.toggleIsOpen()
    },
    setFomData() {
      this.classData.className = this.edit.title
      this.classData.classDescription = this.edit.description
      this.classData.selectedClass = this.edit.class_id
      this.classData.startTime = this.edit.start_time
      this.classData.endTime = this.edit.end_time
    },
    /**
     * toggle isOpen
     * @param {Boolean} value
     * @returns {void}
     * @description toggling the value of IsOpen
     */
    toggleIsOpen(value) {
      this.isOpen = typeof value === 'boolean' ? value : !this.isOpen
    },
    /**
     * close Dropdown
     * @description closing the drop down menus
     */
    closeDropdown() {
      if (this.isActive) {
        this.isActive = false
        this.toggleIsOpen()
      }
    },
    /**
     * Handle Click
     * @param {String} eventName
     * @returns {void}
     * @description Function Description :
     * - handling click on the basis of parameter
     * - emiting toggle to parent component
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    /**
     * Form Check
     * @param {Boolean} invalid
     * @returns {void}
     * @description Function Description :
     * - checking form
     * - emiting toggle to parent component
     */
    addAndEditSectionHandler() {
      this.$store.commit('layout/IS_LOADING', true)
      const data = {
        classes: {
          title: this.classData.className,
          description: this.classData.classDescription,
          start_time: this.classData.startTime,
          end_time: this.classData.endTime,
          class_id: this.classData.selectedClass,
        },
      }
      if (this.edit) data.id = this.edit.id
      this.addNewSection(data)
        .then((res) => {
          this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'sections_count')
          this.$store.commit(
            'toast/NEW',
            {
              type: 'success',
              message: this.edit
                ? `${res.data.title} ` + 'section successfully updated'
                : `${res.data.title} ` + 'section successfully created ',
            },
            { root: true },
          )
          if (this.campusId) {
            this.getClassSections({
              campuseId: this.campusId,
            })
          }

          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
  },
}
</script>
