<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="QUICK_ACT"
      tooltip-title="QUICK_ACT"
      :display-breadcrumb="true"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div
      v-else
      :key="forceUpdate"
      :class="[currentClassScope ? 'xl:grid-cols-2' : 'xl:grid-cols-4']"
      class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 sm:gap-7 mt-5"
    >
      <div
        v-permission="permissions([SCOPE_LEVELS.ROOT_LEVEL])"
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img src="@assets/images/admin/campus.svg" alt="student" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="addCampus">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/campuses"
                class="cursor-pointer text-primary-purple-600"
              >
                Campuses
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-green-700 font-extrabold">
                {{
                  instituteCount ? instituteCount.campuses_count : GENERAL_CONSTANTS.EMPTY_RECORD
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="
          permissions(
            [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
            [],
            [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          )
        "
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img src="@assets/images/admin/subject.svg" alt="Class" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="classModalToggle">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/classes"
                class="cursor-pointer text-primary-purple-600"
              >
                Classes
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-tiles-text font-extrabold">
                {{ instituteCount ? instituteCount.classes_count : GENERAL_CONSTANTS.EMPTY_RECORD }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="
          permissions(
            [SCOPE_LEVELS.SECTION_LEVEL],
            [],
            [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          )
        "
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img src="@assets/images/admin/subject.svg" alt="student" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="addSubject">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/syllabus/subjects"
                class="cursor-pointer text-primary-purple-600"
              >
                Subjects
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-tiles-text font-extrabold">
                {{
                  instituteCount ? instituteCount.subjects_count : GENERAL_CONSTANTS.EMPTY_RECORD
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="
          permissions(
            [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
            [],
            [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          )
        "
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img src="@assets/images/admin/class.svg" alt="student" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="addSection">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/sections"
                class="cursor-pointer text-primary-purple-600"
              >
                Sections
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-blue-800 font-extrabold">
                {{
                  instituteCount ? instituteCount.sections_count : GENERAL_CONSTANTS.EMPTY_RECORD
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="
          permissions(
            [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL],
            [],
            [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          )
        "
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img src="@assets/images/admin/staff.svg" alt="student" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="staffModalToggle">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/staff"
                class="cursor-pointer text-primary-purple-600"
              >
                Staffs
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-tiles-color font-extrabold">
                {{ instituteCount ? instituteCount.staff_count : GENERAL_CONSTANTS.EMPTY_RECORD }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="
          permissions([
            SCOPE_LEVELS.ROOT_LEVEL,
            SCOPE_LEVELS.CAMPUS_LEVEL,
            SCOPE_LEVELS.CLASS_LEVEL,
            SCOPE_LEVELS.SECTION_LEVEL,
          ])
        "
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img
              class="w-16 h-16 p-3.5 rounded-full bg-yellow-50"
              src="@assets/images/admin/teacher.svg"
              alt="student"
            />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="teacherModalToggle">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/teachers"
                class="cursor-pointer text-primary-purple-600"
              >
                Teachers
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-yellow-400 font-bold">
                {{
                  instituteCount ? instituteCount.teachers_count : GENERAL_CONSTANTS.EMPTY_RECORD
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="
          permissions([
            SCOPE_LEVELS.ROOT_LEVEL,
            SCOPE_LEVELS.CAMPUS_LEVEL,
            SCOPE_LEVELS.CLASS_LEVEL,
            SCOPE_LEVELS.SECTION_LEVEL,
          ])
        "
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img
              class="p-3.5 rounded-full bg-red-100"
              src="@assets/images/admin/student.svg"
              alt="student"
            />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="addStudent">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/students"
                class="cursor-pointer text-primary-purple-600"
              >
                Students
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl font-bold text-red-900">
                {{
                  instituteCount ? instituteCount.students_count : GENERAL_CONSTANTS.EMPTY_RECORD
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="
          permissions(
            [SCOPE_LEVELS.CAMPUS_LEVEL],
            [],
            [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
          )
        "
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img src="@assets/images/admin/subject.svg" alt="Class" />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <div class="flex cursor-pointer items-center" @click="roomModalToggle">
                <icon class="icon w-5 h-5" icon="plus-circle" />
              </div>
              <router-link
                v-i18n="dashboard"
                to="/rooms"
                class="cursor-pointer text-primary-purple-600"
              >
                Rooms
              </router-link>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-tiles-text font-extrabold">
                {{ instituteCount ? instituteCount.rooms_count : GENERAL_CONSTANTS.EMPTY_RECORD }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="permissions([SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL])"
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img
              class="p-3.5 rounded-full bg-tiles-backgroud"
              src="@assets/images/admin/business.svg"
              alt="student"
            />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <span v-i18n="dashboard">Roles</span>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-yellow-500 font-extrabold">
                {{ instituteCount ? instituteCount.roles_count : GENERAL_CONSTANTS.EMPTY_RECORD }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-permission="permissions([SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL])"
        class="h-24 p-5 bg-bg-color-white col-span-1 rounded-lg grid grid-cols-2 gap-4"
      >
        <div
          class="col-span-2 flex ltr:flex-row rtl:flex-row-reverse justify-around md:justify-between"
        >
          <div class="flex ltr:flex-1">
            <img
              class="p-3.5 rounded-full bg-tiles-backgroud"
              src="@assets/images/admin/business.svg"
              alt="student"
            />
          </div>
          <div class="flex justify-end gap-2.5 flex-col">
            <div class="inline-flex gap-2 text-sm rtl:flex-row-reverse">
              <span v-i18n="dashboard">ALL_USERS</span>
            </div>
            <div class="flex ltr:justify-end">
              <p class="text-2xl text-yellow-500 font-extrabold">
                {{ instituteCount ? instituteCount.users_count : GENERAL_CONSTANTS.EMPTY_RECORD }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ClassModal v-if="modalClass" :modal="modalClass" @toggle="classModalToggle"></ClassModal>
    <SubjectModal v-if="modalSubject" :modal="modalSubject" @toggle="addSubject"></SubjectModal>
    <SectionModal v-if="modalSection" :modal="modalSection" @toggle="addSection"></SectionModal>
    <StaffModal v-if="modalStaff" :modal="modalStaff" @toggle="staffModalToggle"></StaffModal>
    <CampusModal v-if="modalCampus" :modal="modalCampus" @toggle="addCampus"></CampusModal>
    <RoomModal v-if="modalRoom" :modal="modalRoom" @toggle="roomModalToggle"></RoomModal>
    <StudentModal v-if="modalStudent" :modal="modalStudent" @toggle="addStudent"></StudentModal>
    <TeacherModal
      v-if="modalTeacher"
      :modal="modalTeacher"
      @toggle="teacherModalToggle"
    ></TeacherModal>
  </div>
</template>

<script>
import CampusModal from '@/src/router/views/admin/modals/CampusModal.vue'
import SectionModal from '@/src/router/views/admin/modals/AddSectionModal.vue'
import SubjectModal from '@/src/router/views/admin/modals/SubjectModal.vue'
import StaffModal from '@/src/router/views/admin/modals/StaffModal.vue'
import TeacherModal from '@/src/router/views/admin/modals/TeacherModal.vue'
import StudentModal from '@router/views/admin/modals/AddStudentModal.vue'
import icon from '@components/icons/icon.vue'
import ClassModal from '@/src/router/views/admin/modals/AddClassModal.vue'
import RoomModal from '@src/router/views/admin/modals/RoomModal.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { FEATURES_ENVIRONMENT } from '@/src/services/.env'
import Loader from '@components/BaseComponent/Loader.vue'
import { SCOPE_LEVELS } from '@src/constants/general-constants.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { mapState, mapActions } from 'vuex'
export default {
  components: {
    CampusModal,
    StaffModal,
    StudentModal,
    ClassModal,
    SubjectModal,
    icon,
    SectionModal,
    TeacherModal,
    RoomModal,
    TitleWrapper,
    Loader,
  },
  page: {
    title: 'Quick Actions',
    meta: [{ name: 'description', content: 'Quick Actions' }],
  },
  data() {
    return {
      SCOPE_LEVELS,
      TENANT_ROLES,
      GENERAL_CONSTANTS,
      modalCampus: false,
      modalStaff: false,
      modalStudent: false,
      modalRoles: false,
      modalClass: false,
      modalSubject: false,
      modalDepartment: false,
      modalSection: false,
      modalTeacher: false,
      modalRoom: false,
      enrolTeacherModal: false,
      forceUpdate: 1,
      dashboard: 'dashboard',
      FEATURES_ENVIRONMENT,
      allowedFeatures: [
        'Campuses',
        'Classes',
        'Subjects',
        'Sections',
        'Staffs',
        'Teachers',
        'Students',
        'Roles',
        'All Users',
      ],
    }
  },
  computed: {
    isAdmin() {
      return [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN].includes(this.activeRole)
    },
    ...mapState('instituteCount', ['instituteCount']),
    ...mapState('layout', ['currentClassScope', 'activeRole', 'isLoading']),
  },
  watch: {
    instituteCount: {
      deep: true,
      handler(value) {
        this.forceUpdate++
      },
    },
  },
  created() {
    if (this.isAdmin) this.getInstituteCount()
  },
  methods: {
    addStudent() {
      this.modalStudent = !this.modalStudent
    },
    addCampus() {
      this.modalCampus = !this.modalCampus
    },
    staffModalToggle() {
      this.modalStaff = !this.modalStaff
    },
    permissions(
      basedOn = [],
      environment = [],
      roles = [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN],
    ) {
      return {
        env: environment,
        roles: roles,
        basedOn: basedOn,
      }
    },
    departmentModalToggle() {
      this.modalDepartment = !this.modalDepartment
    },
    teacherModalToggle() {
      this.modalTeacher = !this.modalTeacher
    },
    classModalToggle() {
      this.modalClass = !this.modalClass
    },
    addSection() {
      this.modalSection = !this.modalSection
    },
    addSubject() {
      this.modalSubject = !this.modalSubject
    },
    roomModalToggle() {
      this.modalRoom = !this.modalRoom
    },
    enrolTeacherModalToggle() {
      this.enrolTeacherModal = !this.enrolTeacherModal
    },
    ...mapActions('instituteCount', ['getInstituteCount']),
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>
